import { StackNavigationOptions, createStackNavigator } from '@react-navigation/stack';
import { NavigationHeader } from 'src/navigation/NavigationHeader';
import { BOOKING_ACCESS, CHECK_IN_INFORMATION_SCREEN_NAME, CHECK_IN_SEAT_SCREEN_NAME } from 'src/navigation/constants';
import { CheckinStackParamList } from 'src/types/root.type';
import { CheckinInformation } from 'src/screens/checkin/CheckinInformation';
import { CheckinSeat } from 'src/screens/checkin/CheckinSeat';
import { isWeb } from 'src/utils/environment.utils';
import { CheckIn } from 'src/screens/checkin/CheckIn';

const Stack = createStackNavigator<CheckinStackParamList>();

const screenOptions: StackNavigationOptions = {
  headerShown: true,
  header: NavigationHeader,
  title: 'Check In',
};

export const CheckinStack = () => {
  const initialRouteName = isWeb() ? BOOKING_ACCESS : CHECK_IN_INFORMATION_SCREEN_NAME;

  return (
    <Stack.Navigator screenOptions={screenOptions} initialRouteName={initialRouteName}>
      {isWeb() && (
        <Stack.Screen
          name={BOOKING_ACCESS}
          component={CheckIn}
          options={{ header: props => <NavigationHeader {...props} contentStyle="light" hideTitle /> }}
        />
      )}
      <Stack.Screen
        name={CHECK_IN_INFORMATION_SCREEN_NAME}
        component={CheckinInformation}
        options={{ header: props => <NavigationHeader {...props} contentStyle="light" hideTitle /> }}
      />
      <Stack.Screen name={CHECK_IN_SEAT_SCREEN_NAME} component={CheckinSeat} />
    </Stack.Navigator>
  );
};
