import { MaterialCommunityIcons, Entypo, Ionicons } from '@expo/vector-icons';
import { Avatar, HStack, VStack, Box, View, FlatList, Badge, useTheme, ScrollView, Pressable, Divider } from 'native-base';
import { ContentContainer } from 'src/components/container/ContentContainer';
import { BodyText } from 'src/components/typography/BodyText';
import { SmallLabel } from 'src/components/typography/SmallLabel';
import { SubSubHeader } from 'src/components/typography/SubSubHeader';
import { useBooking } from 'src/providers/booking-provider/BookingProvider';
import { COLORS, PRIMARY_COLOR_NUMBER } from 'src/providers/design-provider/constants';
import { capitalize, getInitials } from 'src/utils/string';
import moment from 'moment';
import { useLinkProps } from '@react-navigation/native';
import { CHECK_IN_SEAT_SCREEN_NAME } from 'src/navigation/constants';
import { Greetings } from '../home-page/components/Greetings';
import { useApp } from 'src/providers/app-provider/AppProvider';
import { useEffect } from 'react';
import { isWeb } from 'src/utils/environment.utils';

export interface PassengerItemProps {
  passengerId: string;
  fullName: string;
  type: string;
  isCheckinAvailable: boolean;
  seatNumber?: string;
}

export const OriginDestinationIllustration = ({ color, size }: { color: string; size?: number }) => {
  return (
    <HStack space={2} alignItems="center">
      <Entypo name="dots-three-horizontal" size={size} color={color} />
      <MaterialCommunityIcons name="bus" size={size} color={color} />
      <Entypo name="dots-three-horizontal" size={size} color={color} />
    </HStack>
  );
};

const PassengerItem = ({ fullName, type, isCheckinAvailable, passengerId, seatNumber }: PassengerItemProps) => {
  const { onPress: goToCheckInSeat } = useLinkProps({
    to: { screen: CHECK_IN_SEAT_SCREEN_NAME, params: { passengerNo: passengerId, passengerName: fullName } },
  });

  return (
    <Pressable onPress={goToCheckInSeat} disabled={!isCheckinAvailable || !!seatNumber}>
      {({ isHovered, isPressed, isFocused }) => (
        <Box padding={4} backgroundColor={isFocused || isHovered || isPressed ? 'gray.100' : undefined}>
          <HStack space={[2, 3]} alignItems="center" justifyContent="space-between">
            <HStack space={[2, 3]} alignItems="center">
              <Avatar size="32px">{getInitials(fullName)}</Avatar>
              <VStack>
                <BodyText text={capitalize(fullName)} />
                {seatNumber && <SmallLabel text={`Seat: ${seatNumber}`} />}
              </VStack>
            </HStack>
            <HStack alignItems={'center'} space={1}>
              <Badge size={'sm'} colorScheme="info" variant="subtle">
                {type}
              </Badge>
              {seatNumber ? (
                <MaterialCommunityIcons name={'check'} size={24} color={'green'} />
              ) : (
                isCheckinAvailable && <Entypo name="chevron-small-right" size={24} />
              )}
            </HStack>
          </HStack>
        </Box>
      )}
    </Pressable>
  );
};

export const CheckinInformation = () => {
  const { booking } = useBooking();
  const { setAppLoading } = useApp();
  const { colors } = useTheme();

  useEffect(() => {
    if (booking?.getReservationBySurname) {
      setAppLoading(false);
    } else {
      setAppLoading(true);
    }
  }, [booking?.getReservationBySurname]);

  if (!booking?.getReservationBySurname) return null;

  const isCheckingAvailable = booking.getReservationBySurname.trip.isCheckinAvailable;

  return (
    <ContentContainer contentFullWidth allWhiteBackground statusBarStyle="dark-content">
      <ScrollView backgroundColor={'white'} flex={1} showsVerticalScrollIndicator={false} bounces={false}>
        <Greetings title="Check In" subtitle={`Reference: ${booking.getReservationBySurname.reference}`} contentStyle="light" />
        <Box
          backgroundColor="white"
          marginTop={'-60px'}
          shadow={2}
          borderRadius={8}
          marginRight={isWeb() ? '15%' : 4}
          marginLeft={isWeb() ? '15%' : 4}
          paddingY={4}
          paddingX={10}
        >
          <HStack justifyContent="space-between" alignItems="center">
            <VStack flex={1}>
              <SubSubHeader text={booking.getReservationBySurname.schedule.route.branch.name} />
              <SmallLabel text={`(${booking.getReservationBySurname.schedule.route.branch.address.state})`} />
            </VStack>
            <OriginDestinationIllustration color={colors.primary[PRIMARY_COLOR_NUMBER]} size={20} />
            <VStack alignItems="flex-end" flex={1}>
              <SubSubHeader text={booking.getReservationBySurname.schedule.route.destination.name.toUpperCase()} />
              <SmallLabel text={`(${booking.getReservationBySurname.schedule.route.destination.state ?? 'Abuja'})`} />
            </VStack>
          </HStack>

          <HStack justifyContent="space-between">
            <HStack marginTop={5}>
              <VStack space={1}>
                <HStack space={2}>
                  <MaterialCommunityIcons name="bus" size={20} color={'#cccccc'} />
                  <BodyText text={moment(booking.getReservationBySurname.trip.dateTime).format('DD MMM, YYYY')} />
                </HStack>
                <HStack space={2}>
                  <MaterialCommunityIcons name="clock-time-five-outline" size={20} color={'#cccccc'} />
                  <BodyText text={moment(booking.getReservationBySurname.trip.dateTime).format('HH:SS A')} />
                </HStack>
              </VStack>
            </HStack>
            <VStack alignItems="center" justifyItems={'center'} justifyContent={'center'} alignContent={'center'} space={2}>
              <Ionicons
                name={isCheckingAvailable ? 'checkmark-circle-sharp' : 'warning'}
                size={24}
                color={isCheckingAvailable ? colors.success[300] : colors.warning[300]}
              />
              <BodyText text={isCheckingAvailable ? 'Checkin Available' : 'Checkin Unavailable'} />
            </VStack>
          </HStack>
        </Box>

        <View marginTop={8} marginX={4} paddingX={isWeb() ? '15%' : undefined} marginBottom={10}>
          <SubSubHeader text="Passengers" />
          <SmallLabel text={isCheckingAvailable ? 'Select passenger to check-in' : 'Passengers for checkin'} />
          <Box borderColor={COLORS.grey} borderWidth={2} borderRadius={2} marginTop={2}>
            <FlatList
              scrollEnabled={false}
              data={booking.getReservationBySurname.tickets}
              ItemSeparatorComponent={() => <Divider backgroundColor={'gray.100'} margin={1} />}
              renderItem={({ item }) => (
                <PassengerItem
                  fullName={item.passenger.fullName}
                  type={item.passenger.type}
                  isCheckinAvailable={isCheckingAvailable}
                  passengerId={item.passenger.id}
                  seatNumber={item.passenger.seat?.number}
                />
              )}
              keyExtractor={item => item.passenger.id}
            />
          </Box>
        </View>
      </ScrollView>
    </ContentContainer>
  );
};
