import { useLinkProps } from '@react-navigation/native';
import { capitalize } from 'lodash';
import moment from 'moment';
import { Box, Button, VStack, View, useTheme } from 'native-base';
import { useEffect, useState } from 'react';
import { DateTimePicker } from 'src/components/date-picker/DatePicker';
import { NewSelect } from 'src/components/select/NewSelect';
import { Select } from 'src/components/select/Select';
import { BodyText } from 'src/components/typography/BodyText';
import { ItenaryInput } from 'src/graphql/generated/types';
import { useFindTerminalLazyQuery } from 'src/graphql/ticketing_api/queries/find-terminal';
import { useGetAvailablityLazyQuery } from 'src/graphql/ticketing_api/queries/get-availability';
import { useGetTerminalsQuery } from 'src/graphql/ticketing_api/queries/get-terminal';
import { AVAILABLITY_SCREEN_NAME, RESERVATION_STACK_NAME } from 'src/navigation/constants';
import { useApp } from 'src/providers/app-provider/AppProvider';
import { useReservation } from 'src/providers/reservation-provider/ReservationProvider';
import { PassengerCounter, PassengerSet } from 'src/screens/book-seat/components/PassengerCounter';

export const SearchCard = () => {
  const { colors } = useTheme();
  const { setAppLoading } = useApp();
  const { setAvailableSchedules, setSearch, clearCache } = useReservation();
  const [adult, setAdult] = useState<number>(1);
  const [selectedTerminal, setSelectedTerminal] = useState<string>();
  const [selectedRoute, setSelectedRoute] = useState<string>();
  const [child, setChild] = useState<number>(0);
  const [infant, setInfant] = useState<number>(0);
  const [departureDate, setDepartureDate] = useState<Date>(new Date());
  const [arrivalDate] = useState<Date>();
  const { data: terminalsList, loading } = useGetTerminalsQuery();
  const [findTerminal, { data: terminal, loading: loadingTerminal }] = useFindTerminalLazyQuery();
  const { onPress: goToAvailablity } = useLinkProps({
    to: {
      screen: RESERVATION_STACK_NAME,
      params: {
        screen: AVAILABLITY_SCREEN_NAME,
        params: {
          adult: String(adult),
          child: String(child),
          infant: String(infant),
          routeId: selectedRoute,
          terminalId: selectedTerminal,
          departureDate: moment(departureDate).format('YYYY-MM-DD'),
        },
      },
    },
  });
  const [getAvailableShcedules, { loading: loadingResults }] = useGetAvailablityLazyQuery();

  const terminals =
    terminalsList?.getTerminals.map(t => ({
      label: `${capitalize(t.address.state)} -> ${capitalize(t.name.toUpperCase())}`,
      value: t.id,
    })) ?? [];
  const routeItems =
    terminal?.findTerminal.routes.map(r => ({
      label: `${capitalize(r.destination.state ?? '')} -> ${capitalize(r.destination.name)}`,
      value: r.id,
    })) ?? [];

  useEffect(() => {
    clearCache();
  }, []);

  useEffect(() => setAppLoading(loadingResults), [loadingResults]);

  useEffect(() => {
    if (selectedTerminal) {
      findTerminal({ variables: { findTerminalId: selectedTerminal } });
    }
  }, [selectedTerminal]);

  const onPassengerCountChange = (set: PassengerSet) => {
    setAdult(set.adult);
    setChild(set.child);
    setInfant(set.infant);
  };

  const onSearch = async () => {
    const depature: ItenaryInput = {
      adult: String(adult),
      child: String(child),
      infant: String(infant),
      routeId: selectedRoute!,
      terminalId: selectedTerminal!,
      date: moment(departureDate).format('YYYY-MM-DD'),
    };

    const response = await getAvailableShcedules({
      variables: {
        input: {
          depature,
          arrival: arrivalDate ? { ...depature, date: moment(arrivalDate).format('YYYY-MM-DD') } : undefined,
        },
      },
    });

    // clear reservation cache
    await clearCache();

    if (response.data) {
      const t = terminalsList?.getTerminals.find(t => t.id === selectedTerminal);
      const r = terminal?.findTerminal.routes.find(r => r.id === selectedRoute);

      setSearch({
        adult,
        child,
        infant,
        terminal: t?.name!,
        terminalState: t?.address.state!,
        destination: r?.destination.name!,
        destinationState: r?.destination.state!,
        date: moment(departureDate).format('YYYY-MM-DD'),
      });
      setAvailableSchedules(response.data.getAvailableSchedule);
      goToAvailablity();
    }
  };

  const hasPassenger = adult + child + infant > 0;
  const canSubmit = hasPassenger && departureDate && selectedTerminal && selectedRoute;
  const isLoading = loadingResults || loadingTerminal;

  return (
    <Box padding={4} shadow={2} borderRadius={8} marginRight={4} marginLeft={4} marginTop={-20} backgroundColor={colors.white}>
      <VStack space={2}>
        <NewSelect
          label="Departure terminal"
          placeholder="Select departure"
          headerTitle="Available terminals"
          isRequired
          isSearchable
          items={terminals}
          loading={loading}
          onValueChange={newValue => setSelectedTerminal(newValue)}
        />

        <NewSelect
          label="Arrival terminal"
          placeholder="Select destination"
          headerTitle="Avaibale destinations"
          isRequired
          isSearchable
          items={routeItems}
          loading={loadingTerminal}
          onValueChange={newValue => setSelectedRoute(newValue)}
        />

        <DateTimePicker
          title="Departure date"
          label="Departing On"
          mode="date"
          isRequired
          minimumDate={new Date()}
          onDateChange={d => setDepartureDate(d)}
        />
        <PassengerCounter label="Passengers" isRequired onComplete={onPassengerCountChange} defaults={{ adult, child, infant }} />
        <View mt="10">
          <Button size="lg" alignSelf="center" width="full" onPress={onSearch} disabled={!canSubmit}>
            <BodyText text="Search" color="#fff" />
          </Button>
        </View>
      </VStack>
    </Box>
  );
};
