import { Avatar, Box, HStack, Pressable, ScrollView, VStack, useTheme } from 'native-base';
import { FC } from 'react';
import { BodyText } from 'src/components/typography/BodyText';
import { SmallLabel } from 'src/components/typography/SmallLabel';
import { CreatePassengersInput } from 'src/graphql/generated/types';
import { TripSeatMapQuery_tripSeatMap_Seat } from 'src/graphql/ticketing_api/queries/get-trip-seatmap';
import { PRIMARY_COLOR_NUMBER } from 'src/providers/design-provider/constants';
import { getInitials } from 'src/utils/string';

interface PassengerTabProps {
  passengers: Partial<CreatePassengersInput>[];
  setActiveIndex?: (index: number) => void;
  activeIndex: number;
  seats: TripSeatMapQuery_tripSeatMap_Seat[];
}

interface PassengerTabItemProps {
  isActive?: boolean;
  name: string;
  seat?: string;
  onPress: () => void;
  isFirst: boolean;
  isLast: boolean;
}

const PassengerTabItem: FC<PassengerTabItemProps> = ({ isActive, name, seat, onPress, isFirst, isLast }) => {
  return (
    <Pressable flex={1} onPress={onPress} minW={200}>
      {({ isHovered }) => {
        const { colors } = useTheme();
        let bg = isHovered ? colors.coolGray['100'] : colors.white;
        bg = isActive ? colors.primary[PRIMARY_COLOR_NUMBER] : bg;
        return (
          <Box padding={3} backgroundColor={bg} borderLeftRadius={isFirst ? 4 : 1} borderRightRadius={isLast ? 4 : 1}>
            <HStack space={2} justifyContent={'center'}>
              <Avatar
                bg={isActive ? bg : colors.white}
                _text={{ color: isActive ? colors.white : 'primary.400' }}
                borderColor={isActive ? colors.white : 'primary.500'}
                borderWidth={1}
              >
                {getInitials(name)}
              </Avatar>
              <VStack justifyContent="space-between">
                <BodyText text={name} bold color={isActive ? colors.white : undefined} />
                <HStack alignItems="center" space={2}>
                  <BodyText text="Seat:" color={isActive ? colors.white : undefined} bold />
                  <SmallLabel text={seat ?? 'No seat selected'} color={isActive ? colors.white : undefined} />
                </HStack>
              </VStack>
            </HStack>
          </Box>
        );
      }}
    </Pressable>
  );
};

export const SelectSeatPassengerTab: FC<PassengerTabProps> = ({ passengers, setActiveIndex, activeIndex, seats }) => {
  return (
    <Box flexDirection="row" borderWidth={1} borderColor={'gray.300'} borderRadius={4} width={'100%'}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <HStack space={3}>
          {passengers.map((p, i) => {
            const seatId = passengers[i].seatId;
            const seat = seatId ? seats.find(s => s.id === seatId.toString())?.number : undefined;
            return (
              <PassengerTabItem
                key={`${i}_passenger`}
                name={p.fullName ?? ''}
                seat={seat}
                onPress={() => setActiveIndex && setActiveIndex(i)}
                isActive={activeIndex === i}
                isFirst={i === 0}
                isLast={i === passengers.length - 1}
              />
            );
          })}
        </HStack>
      </ScrollView>
    </Box>
  );
};
