import Constants from 'expo-constants';
import { Platform } from 'react-native';

export enum EnvkeysEnum {
  ENV = 'ENV',
  AUTH_TOKEN = 'AUTH_TOKEN',
  LEGAL_URL = 'LEGAL_URL',
  PRIVACY_URL = 'PRIVACY_URL',
  ITUNES_ITEM_ID = 'ITUNES_ITEM_ID',
  FLUTTERWAVE_KEY = 'FLUTTERWAVE_KEY',
  PAYSTACK_KEY = 'PAYSTACK_KEY',
  PAYSTACK_SUBACCOUNT_CODE = 'PAYSTACK_SUBACCOUNT_CODE',
  FLUTTERWAVE_SUBACCOUNT_CODE = 'FLUTTERWAVE_SUBACCOUNT_CODE',
  GATEWAY_URL = 'GATEWAY_URL',
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
}

export const getEnvVariable = (key: EnvkeysEnum): string | null => {
  if (Constants.expoConfig?.extra) {
    return Constants.expoConfig.extra[key] ?? null;
  }
  return null;
};

export const isWeb = (): boolean => {
  return Platform.OS === 'web';
};
