import React from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withRepeat, withTiming } from 'react-native-reanimated';

export const LOADING_STATE_TEST_ID = 'LoadingState';

export const LoadingState = () => {
  const rotation = useSharedValue(0);

  // Start animation on mount
  React.useEffect(() => {
    rotation.value = withRepeat(
      withTiming(360, {
        duration: 1300, // Rotation duration in milliseconds
        easing: Easing.linear,
      }),
      -1 // Infinite repetition
    );
  }, [rotation]);

  // Define the animated style
  const animatedStyle = useAnimatedStyle(() => ({
    transform: [
      { rotate: `${rotation.value}deg` }, // Rotate the image
    ],
  }));

  return (
    <View testID={LOADING_STATE_TEST_ID}>
      <Animated.Image
        source={require('../../assets/images/icon.png')} // Replace with your image path
        style={[styles.image, animatedStyle]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f8f8f8', // Background color
  },
  image: {
    width: 50, // Image width (30% of screen width)
    height: 50, // Image height (keep it square)
    resizeMode: 'contain', // Maintain aspect ratio
  },
});
