import { WebView } from 'react-native-webview';
import Constants from 'expo-constants';
import { StyleSheet } from 'react-native';
import { useRoute } from '@react-navigation/native';

export const LocalWebView = () => {
  const { params } = useRoute();
  return <WebView style={styles.container} source={{ uri: (params as any)?.url }} />;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
