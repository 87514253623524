import * as Application from 'expo-application';
import { VStack, Box, HStack, Pressable, useTheme } from 'native-base';
import { ContentContainer } from 'src/components/container/ContentContainer';
import { BodyText } from 'src/components/typography/BodyText';
import { Greetings } from '../home-page/components/Greetings';
import { ScrollView } from 'react-native-gesture-handler';
import { ReactNode } from 'react';
import AntDesign from '@expo/vector-icons/AntDesign';
import Entypo from '@expo/vector-icons/Entypo';
import FontAwesome from '@expo/vector-icons/FontAwesome';
import { Linking, Platform } from 'react-native';
import { EnvkeysEnum, getEnvVariable } from 'src/utils/environment.utils';
import { useLinkProps } from '@react-navigation/native';
import { WEB_SCREEN_NAME } from 'src/navigation/constants';

const moreOptions: { name: string; icon: ReactNode; link?: string | null }[] = [
  { name: 'Rate the app', icon: <AntDesign name="staro" size={30} color="#cccccc" /> },
  {
    name: 'Legal',
    icon: <FontAwesome name="balance-scale" size={30} color="#cccccc" />,
    link: getEnvVariable(EnvkeysEnum.LEGAL_URL),
  },
  { name: 'Privacy', icon: <AntDesign name="Safety" size={30} color="#cccccc" />, link: getEnvVariable(EnvkeysEnum.PRIVACY_URL) },
];

const MoreItem: React.FC<(typeof moreOptions)[number] & { divider?: boolean }> = ({ icon, name, divider, link }) => {
  const { onPress: goToWebView } = useLinkProps({ to: { screen: WEB_SCREEN_NAME, params: { url: link } } });
  const { colors } = useTheme();

  const onItemSelected = () => {
    if (name === 'Rate the app') {
      if (Platform.OS === 'android') {
        Linking.openURL(`market://details?id=${Application.applicationId}&showAllReviews=true`);
      }
      if (Platform.OS === 'ios') {
        Linking.openURL(
          `itms-apps://itunes.apple.com/app/viewContentsUserReviews/id${getEnvVariable(
            EnvkeysEnum.ITUNES_ITEM_ID
          )}?action=write-review`
        ).catch(e => console.log('ios error', e));
      }
    }
    if (link) {
      goToWebView();
    }
  };

  return (
    <Pressable onPress={onItemSelected}>
      {({ isPressed, isFocused }) => {
        const highlight = isFocused || isPressed;
        return (
          <Box paddingX={4} backgroundColor={highlight ? colors.gray[100] : undefined}>
            <HStack justifyContent="space-between" borderBottomWidth={divider ? 1 : 0} paddingY={4} borderBottomColor={'#f5f5f5'}>
              <HStack space={5} alignItems="center">
                {icon}
                <BodyText text={name} fontSize={18} />
              </HStack>
              <Entypo name="chevron-small-right" size={24} color="#cccccc" />
            </HStack>
          </Box>
        );
      }}
    </Pressable>
  );
};

export const MoreScreen = () => {
  return (
    <ContentContainer contentFullWidth allWhiteBackground statusBarStyle="dark-content">
      <ScrollView bounces={false}>
        <Greetings title="More" subtitle={`App version: ${Application.nativeApplicationVersion}`} contentStyle="dark" height={120} />
        <VStack>
          {moreOptions.map((option, index) => (
            <MoreItem
              key={option.name}
              name={option.name}
              icon={option.icon}
              link={option.link}
              divider={index < moreOptions.length - 1}
            />
          ))}
        </VStack>
      </ScrollView>
    </ContentContainer>
  );
};
