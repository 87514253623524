import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import { CreateReservationMutation, CreateReservationMutationVariables } from './__generated__/create-reservation.generated';

export type CreatePassengerInput = CreateReservationMutationVariables['input']['departure']['passengers'][number];

const CREATE_RESERVATION_MUTATION = gql`
  mutation CreateReservation($input: CreateReservationInput!, $provider: String!) {
    createOrEditReservation(input: $input, provider: $provider) {
      tickets {
        id
        passengerId
      }
      passengers {
        id
        fullName
        email
        phone
        isPrimaryPassenger
      }
      transaction {
        id
        amount
        status
        provider
        reference
      }
      authorizationUrl
      reservation {
        id
        reference
        onHoldTill
        contactEmail
      }
      totalFare
    }
  }
`;

export const useCreateReservationMutation = (): MutationTuple<CreateReservationMutation, CreateReservationMutationVariables> =>
  useMutationErrorNotification<CreateReservationMutation, CreateReservationMutationVariables>(CREATE_RESERVATION_MUTATION);
