import { Checkbox, FormControl, ISelectProps, Select as NBSelect, Spinner, useTheme } from 'native-base';
import { useEffect, useState } from 'react';
import Dropdown from 'react-native-input-select';
import { SimpleLineIcons } from '@expo/vector-icons';
import { TFlatList } from 'react-native-input-select/lib/typescript/types/index.types';
import { COLORS, FONTS, PRIMARY_COLOR_NUMBER, THEME_INPUT_HEIGHT } from 'src/providers/design-provider/constants';
import { SubHeader } from '../typography/SubHeader';

export interface SelectItem {
  label: string;
  value: string;
}

export interface SelectProps {
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  helperText?: string;
  items: SelectItem[];
  loading?: boolean;
  isMultiple?: boolean;
  isSearchable?: boolean;
  headerTitle?: string;
}

export const NewSelect = ({
  label,
  items,
  helperText,
  isRequired,
  mt,
  width,
  loading,
  isDisabled,
  isMultiple,
  isSearchable,
  placeholder,
  headerTitle,
  ...props
}: SelectProps & ISelectProps) => {
  const [selectedValue, setSelectedValue] = useState<string | number>();
  const { colors } = useTheme();

  useEffect(() => {
    if (props.onValueChange) props.onValueChange(selectedValue as string);
  }, [selectedValue]);

  useEffect(() => {
    if (props.defaultValue) setSelectedValue(props.defaultValue);
  }, [props.defaultValue]);

  const itemList: TFlatList = items.map(item => ({ ...item }));

  const inputStyle = {
    borderWidth: 1,
    backgroundColor: COLORS.grey,
    borderColor: colors.muted[300],
    borderRadius: 4,
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: THEME_INPUT_HEIGHT,
    height: THEME_INPUT_HEIGHT * 4,
  };

  return (
    <FormControl isRequired={isRequired} mt={mt} width={width}>
      {label && <FormControl.Label>{label}</FormControl.Label>}
      <Dropdown
        placeholder={placeholder}
        options={itemList}
        optionLabel={'label'}
        optionValue={'value'}
        selectedValue={selectedValue}
        onValueChange={(itemValue: any) => setSelectedValue(itemValue)}
        isMultiple={isMultiple}
        isSearchable={isSearchable}
        searchControls={{
          textInputStyle: {
            fontFamily: FONTS.REGULAR,
            ...inputStyle,
          },
          textInputContainerStyle: { backgroundColor: COLORS.grey },
        }}
        listHeaderComponent={headerTitle ? <SubHeader text={headerTitle} marginLeft={4} /> : null}
        primaryColor={colors.primary[PRIMARY_COLOR_NUMBER]}
        dropdownStyle={inputStyle}
        checkboxControls={{ checkboxLabelStyle: { fontFamily: FONTS.REGULAR } }}
        // Using deprecated checkboxLabelStyle because checkboxControls => checkboxLabelStyle
        // doesn't work on ios and possibly android
        checkboxLabelStyle={{ fontFamily: FONTS.REGULAR }}
        selectedItemStyle={{ fontFamily: FONTS.REGULAR }}
        dropdownIcon={loading ? <Spinner accessibilityLabel="Loading" /> : <SimpleLineIcons name="arrow-down" size={12} />}
        dropdownIconStyle={{ top: 18, right: 18 }}
        placeholderStyle={{ color: colors.gray[400], fontFamily: FONTS.REGULAR }}
        dropdownContainerStyle={{ marginBottom: 0 }}
        listComponentStyles={{
          listEmptyComponentStyle: {
            color: 'red',
            fontFamily: FONTS.REGULAR,
          },
          itemSeparatorStyle: {
            opacity: 0,
            borderColor: 'white',
            borderWidth: 2,
            backgroundColor: 'cyan',
          },
          sectionHeaderStyle: {
            padding: 10,
            backgroundColor: 'cyan',
          },
        }}
        listControls={{
          selectAllText: 'Choose everything',
          unselectAllText: 'Remove everything',
          emptyListMessage: 'No record found',
        }}
      />

      {helperText && <FormControl.HelperText>{helperText}</FormControl.HelperText>}
    </FormControl>
  );
};
