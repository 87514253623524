import { Button, Center, HStack, Icon, Pressable, useToast, VStack } from 'native-base';
import { FC } from 'react';
import LottieView from 'lottie-react-native';
import { SubHeader } from 'src/components/typography/SubHeader';
import { BodyText } from 'src/components/typography/BodyText';
import { SubSubHeader } from 'src/components/typography/SubSubHeader';
import { ContentContainer } from 'src/components/container/ContentContainer';
import { useReservation } from 'src/providers/reservation-provider/ReservationProvider';
import { useLinkProps } from '@react-navigation/native';
import { HOME_SCREEN_NAME, LANDING_SCREEN_NAME } from 'src/navigation/constants';
import { isWeb } from 'src/utils/environment.utils';
import FeatherIcon from '@expo/vector-icons/Feather';
import { COLORS } from 'src/providers/design-provider/constants';
import * as Clipboard from 'expo-clipboard';

export const ReservationSuccess: FC = () => {
  const { completeBooking, clearCache } = useReservation();
  const { show } = useToast();
  const { onPress: goHome } = useLinkProps({
    to: { screen: isWeb() ? LANDING_SCREEN_NAME : HOME_SCREEN_NAME },
  });

  const homeClicked = () => {
    clearCache();
    goHome();
  };

  const onCopyReferenceClicked = async () => {
    Clipboard.setStringAsync(completeBooking?.reservation.reference ?? '');
    show({ title: 'Copied' });
  };

  return (
    <ContentContainer allWhiteBackground>
      <Center>
        <VStack space={5}>
          <Center>
            <LottieView
              autoPlay
              source={require('src/assets/lottie/completed-animation.json')}
              resizeMode="contain"
              style={{ width: 300 }}
            />
          </Center>
          <SubHeader text="Reservation confirmed" textAlign="center" />
          <BodyText
            maxWidth={500}
            text="Your reservation has been confirmed and your reservation information has been sent to your email"
            textAlign="center"
          />
          <VStack space={5}>
            <SubHeader text="Booking Reference:" textAlign="center" />
            {/* We use the group tag as it's the current identified for all bookings in a reservation */}
            <HStack space={2} alignItems="center" justifyContent="center">
              <SubSubHeader text={`#${completeBooking?.reservation.reference ?? ''}`} textAlign="center" />
              <Pressable onPress={onCopyReferenceClicked}>
                {({ isPressed }) => {
                  return <FeatherIcon name="copy" size={24} selectionColor={COLORS.grey} color={isPressed ? 'red' : undefined} />;
                }}
              </Pressable>
            </HStack>
            <Button onPress={homeClicked} marginTop={2}>
              Home
            </Button>
          </VStack>
        </VStack>
      </Center>
    </ContentContainer>
  );
};
