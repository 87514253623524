import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import { CompleteBookingMutation, CompleteBookingMutationVariables } from './__generated__/complete-booking.generated';

export type CompleteBooking = CompleteBookingMutation['completeBooking'];
export type CompleteBookingReservation = CompleteBookingMutation['completeBooking']['reservation'];

const COMPLETE_BOOKING_MUTATION = gql`
  mutation CompleteBooking($reservationId: Float!) {
    completeBooking(reservationId: $reservationId) {
      reservation {
        reference
        trip {
          dateTime
          vehicleClass {
            name
          }
        }
        schedule {
          route {
            destination {
              name
              state
            }
            branch {
              name
              address {
                state
              }
            }
          }
        }
      }
      transaction {
        id
        amount
        type
        groupTag
        reference
      }
      tickets {
        id
        passengerId
        status
      }
    }
  }
`;

export const useCompleteBookingnMutation = (): MutationTuple<CompleteBookingMutation, CompleteBookingMutationVariables> =>
  useMutationErrorNotification<CompleteBookingMutation, CompleteBookingMutationVariables>(COMPLETE_BOOKING_MUTATION);
