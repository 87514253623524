import { Button, VStack } from 'native-base';
import { useEffect, useState } from 'react';
import { Alert } from 'src/components/alerts/Alert';
import { ContentContainer } from 'src/components/container/ContentContainer';
import { Input } from 'src/components/input/Input';
import { BodyText } from 'src/components/typography/BodyText';
import { PageHeader } from 'src/components/typography/PageHeader';
import { useApp } from 'src/providers/app-provider/AppProvider';
import { useBooking } from 'src/providers/booking-provider/BookingProvider';
import { isWeb } from 'src/utils/environment.utils';

export const GetBookingForm = ({
  defaultReference,
  onBookingRetrieved,
  headerText,
  messageText,
}: {
  defaultReference?: string;
  onBookingRetrieved?: () => void;
  headerText?: string;
  messageText?: string;
}) => {
  const { setAppLoading } = useApp();
  const { getReservation, booking, loading, bookingError } = useBooking();
  const [reference, setReference] = useState<string>(defaultReference ?? '');
  const [surname, setSurname] = useState<string>();

  useEffect(() => {
    if (defaultReference) {
      setReference(defaultReference);
    }
  }, [defaultReference]);

  useEffect(() => {
    setAppLoading(loading ?? false);
  }, [loading]);

  const onSubmit = async () => {
    if (reference && surname) {
      const { data } = await getReservation(reference.trim(), surname.trim());
      if (data?.getReservationBySurname && onBookingRetrieved) {
        onBookingRetrieved();
      }
    }
  };

  const isDisabled = !reference || !surname || loading;
  const hasError = booking?.getReservationBySurname === null || bookingError;

  return (
    <ContentContainer allWhiteBackground statusBarStyle="dark-content">
      {hasError && (
        <Alert
          type="error"
          description={booking?.getReservationBySurname === null ? 'Booking does not exist' : bookingError?.message ?? ''}
        />
      )}
      <VStack alignItems="center" space={3} marginBottom={10}>
        <PageHeader text={headerText ? headerText : 'Manage your booking'} />
        <BodyText
          text={messageText ? messageText : 'Enter your details to see your itinery, make changes and add extra services.'}
          maxWidth={500}
          textAlign="center"
        />
      </VStack>
      <VStack space={3} maxWidth={isWeb() ? 500 : undefined} alignSelf="center" width={'100%'}>
        <Input
          label="Booking reference"
          isRequired
          placeholder="Enter booking reference"
          isDisabled={loading}
          value={reference}
          onChangeText={setReference}
        />
        <Input label="Surname" isRequired placeholder="Enter surname for the booking" isDisabled={loading} onChangeText={setSurname} />
        <Button size="lg" marginTop={6} onPress={onSubmit} isDisabled={isDisabled} disabled={isDisabled}>
          Get Booking
        </Button>
      </VStack>
    </ContentContainer>
  );
};
