import { NavigationContainer } from '@react-navigation/native';
import { AppStack } from 'src/navigation/AppStack';
import { linkingConfig } from 'src/navigation/linking-config';
import { BookingProvider } from 'src/providers/booking-provider/BookingProvider';
import { ReservationProvider } from 'src/providers/reservation-provider/ReservationProvider';

export const Router = () => {
  return (
    <NavigationContainer linking={linkingConfig}>
      <ReservationProvider>
        <BookingProvider>
          <AppStack />
        </BookingProvider>
      </ReservationProvider>
    </NavigationContainer>
  );
};
