import { StackNavigationOptions, createStackNavigator } from '@react-navigation/stack';
import { NavigationHeader } from 'src/navigation/NavigationHeader';
import { BOOKING_ACCESS, BOOKING_INFORMATION } from 'src/navigation/constants';
import { BookingStackParamList } from 'src/types/root.type';
import { BookingInformation } from 'src/screens/bookings/BookingInformation';
import { isWeb } from 'src/utils/environment.utils';
import { Booking } from 'src/screens/bookings/Bookings';

const Stack = createStackNavigator<BookingStackParamList>();

const screenOptions: StackNavigationOptions = {
  headerShown: true,
  header: NavigationHeader,
  title: 'Manage Booking',
};

export const BookingInformationStack = () => {
  const initialRouteName = isWeb() ? BOOKING_ACCESS : BOOKING_INFORMATION;

  return (
    <Stack.Navigator screenOptions={screenOptions} initialRouteName={initialRouteName}>
      {isWeb() && (
        <Stack.Screen
          name={BOOKING_ACCESS}
          component={Booking}
          options={{ header: props => <NavigationHeader {...props} contentStyle="light" hideTitle /> }}
        />
      )}
      <Stack.Screen
        name={BOOKING_INFORMATION}
        component={BookingInformation}
        options={{ header: props => <NavigationHeader {...props} contentStyle="light" hideTitle /> }}
      />
    </Stack.Navigator>
  );
};
