import { Box, HStack, useTheme, VStack } from 'native-base';
import { PageHeader } from 'src/components/typography/PageHeader';
import { PRIMARY_COLOR_NUMBER } from 'src/providers/design-provider/constants';
import { NotificationButton } from './NotificationButton';
import { useLinkProps } from '@react-navigation/native';
import { NOTIFICATION_SCREEN_NAME } from 'src/navigation/constants';
import { FC } from 'react';
import { BodyText } from 'src/components/typography/BodyText';
import { isWeb } from 'src/utils/environment.utils';

interface GreetingsProps {
  title: string;
  subtitle?: string;
  hasNotification?: boolean;
  contentStyle?: 'dark' | 'light';
  backgroundColor?: 'string';
  height?: number | string;
}

export const Greetings: FC<GreetingsProps> = ({
  title,
  hasNotification,
  subtitle,
  contentStyle = 'dark',
  backgroundColor,
  height,
}) => {
  const { colors } = useTheme();
  const { onPress: goToNotifications } = useLinkProps({
    to: { screen: NOTIFICATION_SCREEN_NAME },
  });

  const options = {
    backgroundColor: contentStyle === 'dark' ? 'white' : colors.primary[PRIMARY_COLOR_NUMBER],
    textColor: contentStyle === 'dark' ? undefined : colors.white,
  };

  return (
    <Box
      width="100%"
      height={height ?? 171}
      paddingY={5}
      paddingX={isWeb() ? '15%' : 5}
      backgroundColor={backgroundColor ?? options.backgroundColor}
    >
      <HStack alignContent="center" justifyContent="space-between">
        <VStack>
          <PageHeader text={title ?? ''} color={options.textColor} fontWeight="extrabold" />
          {subtitle && <BodyText text={subtitle} color={options.textColor} fontWeight="extrabold" />}
        </VStack>
        {hasNotification && <NotificationButton onPress={goToNotifications} />}
      </HStack>
    </Box>
  );
};
