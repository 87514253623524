import { StorageKeysEnum } from 'src/constants/storage';
import { Reservation } from 'src/graphql/generated/types';
import { getItem, saveItem } from './storage/store';
import dayjs from 'dayjs';
import { CompleteBookingReservation } from 'src/graphql/ticketing_api/mutations/complete-booking';

export class ReservationManager {
  /**
   * Adds a new reservation to the list of upcoming bookings and updates local storage.
   *
   * @async
   * @function add
   * @param {Reservation} reservation - The reservation object to be added to the existing list of upcoming bookings.
   */
  async add(reservation: CompleteBookingReservation): Promise<void> {
    const reservations = await this.getAll();
    reservations.push(reservation);
    await saveItem(StorageKeysEnum.UPCOMING_BOOKINGS, reservations.sort());
  }

  /**
   * Retrieves all upcoming reservations from local storage, filters out past reservations,
   * and updates the stored data if any outdated reservations are found.
   *
   * @async
   * @function getAll
   * @returns {Promise<[CompleteBookingReservation]>} - A promise that resolves to an array of future reservations.
   */
  async getAll(): Promise<[CompleteBookingReservation]> {
    const reservations = (await getItem<[CompleteBookingReservation]>(StorageKeysEnum.UPCOMING_BOOKINGS)) ?? [];
    const filtered = await this.filterPastReservation(reservations);
    if (reservations.length !== filtered.length) {
      await this.set(filtered);
    }
    const sortedReservations = filtered.sort((a, b) => {
      const dateA = new Date(a.trip.dateTime);
      const dateB = new Date(b.trip.dateTime);
      return dateA.getTime() - dateB.getTime(); // Ascending order
    });
    return sortedReservations;
  }

  /**
   * Saves the provided array of reservations to local storage under the key `UPCOMING_BOOKINGS`.
   *
   * @async
   * @function set
   * @param {CompleteBookingReservation[]} reservations - An array of reservation objects to be saved.
   *
   */
  async set(reservations: CompleteBookingReservation[]): Promise<void> {
    await saveItem(StorageKeysEnum.UPCOMING_BOOKINGS, reservations);
  }

  /**
   * Clears all upcoming reservations by saving an empty array to local storage.
   *
   * @async
   * @function clear
   * @returns {Promise<void>} - A promise that resolves when the reservations have been cleared from local storage.
   *
   */

  async clear(): Promise<void> {
    await saveItem(StorageKeysEnum.UPCOMING_BOOKINGS, []);
  }

  /**
   * Filters out reservations where the trip date and time is in the past.
   *
   * @async
   * @function filterPastReservation
   * @param {CompleteBookingReservation[]} reservations - An array of reservation objects to be filtered.
   * @returns {Promise<[CompleteBookingReservation]>} - A filtered array of reservations where the trip date and time is in the future.
   */
  private async filterPastReservation(reservations: CompleteBookingReservation[]): Promise<[CompleteBookingReservation]> {
    return reservations.filter(reservation => {
      const tripDateTime = new Date(reservation.trip.dateTime);
      return tripDateTime > dayjs().toDate();
    }) as [Reservation];
  }
}
