import { Center, Modal } from 'native-base';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { LoadingState } from 'src/components/loading-state/LoadingState';

export interface AppProviderProps {
  children: ReactNode | ReactNode[];
}

export interface AppContextType {
  isAppLoading: boolean;
  setAppLoading: (loading: boolean) => void;
}

export const AppContext = createContext<AppContextType>({ isAppLoading: false, setAppLoading: (_: boolean) => {} });

export const useApp = () => {
  if (!AppContext) {
    throw Error('useAuth can not be used outside of an AuthContextProvider.');
  }
  return useContext(AppContext);
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const [appLoading, setAppLoadingState] = useState<boolean>(false);

  const setAppLoading = useCallback((loading: boolean) => setAppLoadingState(loading), [setAppLoadingState]);

  return (
    <AppContext.Provider value={{ isAppLoading: appLoading, setAppLoading }}>
      {children}

      {appLoading && (
        <Modal isOpen={true} collapsable={false}>
          <Modal.Content height={'80px'} width={'80px'}>
            <Modal.Body>
              <Center>
                <LoadingState />
              </Center>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      )}
    </AppContext.Provider>
  );
};
