import { MaterialCommunityIcons, Octicons } from '@expo/vector-icons';
import { useIsFocused, useLinkProps } from '@react-navigation/native';
import { Box, Divider, HStack, Image, Pressable, useTheme, View, VStack } from 'native-base';
import { useCallback, useEffect, useState } from 'react';
import { BodyText } from 'src/components/typography/BodyText';
import { SubHeader } from 'src/components/typography/SubHeader';
import { CompleteBookingReservation } from 'src/graphql/ticketing_api/mutations/complete-booking';
import { BOOKING_ACCESS } from 'src/navigation/constants';
import { ReservationManager } from 'src/utils/reservation-manager.utils';

const TripItem = ({ reservation }: { reservation: CompleteBookingReservation }) => {
  const { colors } = useTheme();
  const { onPress: goToBooking } = useLinkProps({
    to: { screen: BOOKING_ACCESS, params: { reference: reservation.reference } },
  });

  return (
    <Pressable onPress={() => goToBooking()}>
      {({ isPressed }) => {
        return (
          <Box shadow={1} width={'100%'} background={isPressed ? colors.gray[100] : 'white'} borderRadius={4} marginBottom={5}>
            <HStack justifyContent="space-between" alignItems="center" padding={3} px={4}>
              <HStack space={2} alignItems={'center'}>
                <Image size={8} resizeMode="contain" source={require('../../../../assets/icon.png')} alt={'Business Logo'} />
                <BodyText text={`#${reservation.reference}`} />
              </HStack>

              <HStack>
                <HStack space={1} alignItems={'center'}>
                  <Octicons name="feed-star" />
                  <BodyText text={reservation.trip.vehicleClass.name} />
                </HStack>
              </HStack>
            </HStack>
            <Divider background="gray.200" />

            <HStack padding={3} justifyContent="space-evenly">
              <VStack alignItems="center" flex={1}>
                <BodyText text={reservation.schedule.route.branch.name} textTransform="capitalize" bold />
                <BodyText text={reservation.schedule.route.branch.address.state ?? ''} color="gray.400" textTransform="capitalize" />
              </VStack>

              <HStack flex={2} space={2} alignItems="center" px={5}>
                <MaterialCommunityIcons name="bus-articulated-end" size={20} color={colors.gray[400]} />
                <Divider flex={1} background={'gray.200'} borderRadius={0.2} />
                <MaterialCommunityIcons name="bus-articulated-front" size={20} color={colors.gray[400]} />
              </HStack>

              <VStack flex={1} alignItems="center">
                <BodyText text={reservation.schedule.route.destination.name} textTransform="capitalize" bold />
                <BodyText text={reservation.schedule.route.destination.state ?? ''} color="gray.400" textTransform="capitalize" />
              </VStack>
            </HStack>
          </Box>
        );
      }}
    </Pressable>
  );
};

export const UpcomingTrips = () => {
  const [upcomingTrips, setUpcomingTrips] = useState<[CompleteBookingReservation]>();
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      loadUpcomingTrips();
    }
  }, [isFocused]);

  const loadUpcomingTrips = useCallback(async () => {
    const reservationManager = new ReservationManager();
    setUpcomingTrips(await reservationManager.getAll());
  }, [setUpcomingTrips]);

  return (
    <View padding={4} marginTop={4}>
      <SubHeader text="Upcoming trips" bold />
      <VStack marginTop={4}>
        {upcomingTrips?.map(reservation => (
          <TripItem key={reservation.reference} reservation={reservation} />
        ))}
      </VStack>
    </View>
  );
};
