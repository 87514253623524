import { StackActions, useLinkProps } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { BOOKING_INFORMATION, MANAGE_BOOKING_STACK_NAME } from 'src/navigation/constants';
import { RootStackParamList } from 'src/types/root.type';
import { GetBookingForm } from './GetBookingForm';

export const Booking = (props: StackScreenProps<RootStackParamList, 'Manage Bookings'>) => {
  const { onPress: goToBookingInformation } = useLinkProps({
    to: { screen: MANAGE_BOOKING_STACK_NAME, params: { screen: BOOKING_INFORMATION } },
  });

  return <GetBookingForm defaultReference={props.route.params?.reference} onBookingRetrieved={goToBookingInformation} />;
};
