import { useLinkProps } from '@react-navigation/native';
import { CHECK_IN_INFORMATION_SCREEN_NAME, CHECK_IN_STACK_NAME } from 'src/navigation/constants';
import { GetBookingForm } from '../bookings/GetBookingForm';
import { CheckinStackParamList, RootBottomNavParamList } from 'src/types/root.type';
import { StackScreenProps } from '@react-navigation/stack';

export const CheckIn = (
  props: StackScreenProps<CheckinStackParamList, 'Booking'> | StackScreenProps<RootBottomNavParamList, 'Check-In'>
) => {
  const { onPress: goToBookingInformation } = useLinkProps({
    to: { screen: CHECK_IN_STACK_NAME, params: { screen: CHECK_IN_INFORMATION_SCREEN_NAME } },
  });

  return (
    <GetBookingForm
      defaultReference={props.route.params?.reference}
      onBookingRetrieved={goToBookingInformation}
      headerText="Online Check-in"
      messageText="Check in online, select your seat and save time at our terminals. You can check‑in online hours ahead of your trip."
    />
  );
};
